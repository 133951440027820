.initials-client {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 22px;
  height: 22px;
  border-radius: 22px;
  background: rgba(71, 71, 71, 0.69);
  backdrop-filter: blur(10px);
  bottom: 4px;
  left: 4px;
  & p {
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    color: white;
    margin: 0;
  }
}

.only-initials-client {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background: rgba(71, 71, 71, 0.69);
  backdrop-filter: blur(10px);
  bottom: 0;
  left: 0;
  & p {
    font-size: 32px;
    font-weight: bold;
    text-align: center;
    color: white;
    margin: 0;
  }
}

.only-initials {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 10;
  background: rgba(71, 71, 71, 0.69);
  backdrop-filter: blur(10px);
  bottom: 0;
  left: 0;
  & p {
    font-size: 32px;
    font-weight: bold;
    text-align: center;
    color: white;
    margin: 0;
  }
}

.client-video-container {
  width: 28%;
  height: 85px;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  margin-right: 10px;
  @media (max-width: 600px) {
    width: 145px;
    height: 105px;
    margin: 0;
    margin-right: 10px;
  }
}

.client-video {
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  width: 100%;
  height: 100%;
  & video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
