#viewer {
  width: 100%;
  height: 100%;
  position: relative;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 0px;
  margin: 0;
  background: #fafafa;
  canvas {
    width: 100%;
    height: 100%;
  }
}

.tooltip {
  position: absolute;
  top: 0;
  left: 0;
  padding: 20px;
  background: #4a4a4a;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  text-align: center;
  line-height: 1.5;
  letter-spacing: normal;
  border-radius: 10%;
  color: #fafafa;
  transform: translate3d(-50%, -100%, 0);
  opacity: 0;
  transition: opacity 0.3s, transform 0.3s;
  &.is-active {
    opacity: 0.75;
    transform: translate3d(-50%, calc(-100% - 80px), 0);
  }
}
